import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import SimpleModernHeadline from '../../components/SimpleModernHeadline/SimpleModernHeadline';
import HeadlineWithImage from '../../components/HeadlineWithImage/HeadlineWithImage';

import img_leistung_11 from '../../images/leistungen/img11.jpg';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="Ernährungsberatung | Dr. K. Kostka in Bochum" description="Dr. K. Kostka bietet Ernährungsberatung mitten in Bochum. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓"/>
    <Section>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={12}>
            <HeadlineWithImage headline={"Ernährungsberatung"} subtitle={"Beratung und Therapie in Bochum"} frontImage={img_leistung_11}></HeadlineWithImage>
          </Col>
        </Row>
        <p>
        Viele, die eine Ernährungsberatung wünschen, haben bereits etliche erfolglose Diäten hinter sich.
        <br/>
        <br/>
        Die Formel ist zwar denkbar einfach: Übersteigt die Kalorienzufuhr den Energieverbrauch, nehmen wir unweigerlich zu.
        Obwohl wir das alle wissen, ist der Weg zu einer für Sie „gesunden“ Ernährung und anhaltenden  Gewichtsreduktion  steinern, vergleichbar mit dem Wunsch, daß Rauchen aufzugeben.
        <br/>
        <br/>
        Mein Behandlungsprogramm beinhaltet dementsprechend nicht nur eine umfassende Schulung, in der Ihnen die Grundlagen der Ernährung vermittelt werden.
        Entscheidend ist ein auf Ihren persönlichen Lebensstil individuell abgestimmter Ernährungs- und Bewegungsplan, der auch ihre Vorerkrankungen berücksichtigt. 
        Es wird nicht nur darauf ankommen, was sie essen, sondern welche Möglichkeiten bestehen, auch ihren Aktivitätslevel zu steigern.
        <br/>
        <br/>
        Der Beginn ist wissenschaftlich fundiertes, standardisiertes ernährungsmedizinisches Staging: Ernährungsprotokoll, Bestimmung der Körperzusammensetzung  (Fett/Muskel/Wasser/Knochen) mittels Bio-Impedanz-Analyse und Ermittlung von Energie- und Nährstoffbedarf.
        <br/>
        <br/>
        Wir werden realistische und realisierbare Ziele besprechen.
        <br/>
        <br/>
        Ihr Ernährungsplan wird sich daran orientieren.
        <br/>
        <br/>
        Nach jeweils 4  Wochen erfolgen 2 weitere Beratungseinheiten und und nach 6 Monaten eine erneute Statusaufnahme mittels Bio-Impedanz-Analyse.
        </p>
      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
